import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import VueRouter from "vue-router";
import i18n from "./locales/setupI18n";

import "./init.css";
import "animate.css";
import "swiper/css/swiper.css";
import VueAwesomeSwiper from "vue-awesome-swiper";
const VueScrollTo = require("vue-scrollto");

const router = new VueRouter({
  mode: "history", // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: [{ path: "/" }],
});

Vue.use(VueRouter);
Vue.use(VueScrollTo);
Vue.use(VueAwesomeSwiper);
import VueScrollactive from "vue-scrollactive";
Vue.use(VueScrollactive);

Vue.config.productionTip = false;

new Vue({ store, i18n, router, render: (h) => h(App) }).$mount("#app");
