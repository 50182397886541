<template>
  <div id="app" class="main-page">
    <Navbar @callback="navChange" />

    <div>
      <Home />
      <Matrix />
      <section
        class="section-sm"
        style="
          background-color: rgb(233, 240, 254);
          box-shadow: rgb(63 62 230 / 26%) 0px -5px 10px 0px;
          margin-top: 5px;
        "
      >
        <div class="container">
          <div ref="content" style="min-height: 30px">
            <transition
              name="fade"
              enter-active-class="animate__animated animate__fadeInUp"
              leave-active-class="animate__animated animate__fadeOutDown"
            >
              <div class="row" v-if="content_show">
                <div
                  v-for="(item, index) in imgList"
                  :key="index"
                  style="height: 25px; flex: 1; text-align: center"
                >
                  <img :src="item.url" height="25" alt="" />
                </div>
              </div>
            </transition>
          </div>
        </div>
      </section>
      <Platform />
      <Settled />
      <Switcher />
    </div>

    <div class="liu">
      <div class="up"></div>
      <div class="qiu" :class="activeName == 'home' ? 'qiu_active' : ''"></div>
      <div
        class="qiu"
        :class="activeName == 'matrix' ? 'qiu_active' : ''"
      ></div>
      <div
        class="qiu"
        :class="activeName == 'platform' ? 'qiu_active' : ''"
      ></div>
      <div
        class="qiu"
        :class="activeName == 'settled' ? 'qiu_active' : ''"
      ></div>
      <div class="down"></div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/navbar";
import Matrix from "@/components/matrix";
import Platform from "@/components/platform";
import Switcher from "@/components/switcher";
import Settled from "@/components/settled";
import Home from "@/components/home";
import { getBrowserLang, getStorage } from "@/locales/setupI18n";

export default {
  name: "App",
  components: {
    Navbar,
    Matrix,
    Platform,
    Settled,
    Switcher,
    Home,
  },
  data() {
    return {
      activeName: "",
      imgList: [
        { url: require("@/assets/images/friend/9.jpg") },
        { url: require("@/assets/images/friend/8.png") },
        { url: require("@/assets/images/friend/zjtd.png") },
        { url: require("@/assets/images/friend/1.png") },
        { url: require("@/assets/images/friend/2.png") },
        { url: require("@/assets/images/friend/3.png") },
        { url: require("@/assets/images/friend/4.png") },
        { url: require("@/assets/images/friend/5.png") },
        { url: require("@/assets/images/friend/6.png") },
        { url: require("@/assets/images/friend/7.png") },
      ],
      content_show: false,
    };
  },
  created() {
    const lang = getStorage("lang");
    if (!lang) {
      this.$store.commit("setLang", getBrowserLang());
    }
    if (window.location.href.indexOf("#") > 0) {
      this.$router.replace({});
    }
  },
  mounted() {
    window.addEventListener("scroll", this.scrollHandle, true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollHandle, true);
  },
  methods: {
    scrollHandle() {
      const content = this.$refs.content.getBoundingClientRect();
      const contentTop = content.top;
      const contentBottom = content.bottom;

      if (contentTop < window.innerHeight - 130) {
        this.content_show = true;
      } else {
        this.content_show = false;
      }
    },
    navChange(val) {
      this.activeName = val;
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  max-width: 1920px;
  margin: 0 auto;
}
.bg-home {
  background-repeat: no-repeat;
  background-image: url("@/assets/images/bg-home.jpg");
  background-size: cover;
  background-position: center;
}
.home-half {
  padding-bottom: 200px;
  padding-top: 180px;
}

.home-title {
  font-size: 46px;
  line-height: 64px;
}

.home-small-title {
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 7px;
}

.display-table-cell {
  display: table-cell;
  vertical-align: middle;
}

.display-table {
  display: table;
  width: 100%;
  height: 100%;
}

.home-up {
  z-index: 10;
  position: relative;
}

.home-desc {
  color: rgba(255, 255, 255, 0.7);
  font-size: 15px;
  max-width: 600px;
}

::v-deep .modal-content {
  background-color: #000000;
  color: white;
  background: none;
  border: none;
}
::v-deep .modal-header {
  border: none;
}
::v-deep .modal-backdrop {
  opacity: 0.5;
}
.liu {
  position: fixed;
  // border: 1px solid #e20b0b;
  height: 60vh;
  right: 4%;
  top: 20vh;
  width: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .up {
    background-repeat: no-repeat;
    background-image: url("@/assets/images/icon/jiantou-b.png");
    background-size: cover;
    background-position: center;
    width: 10px;
    height: 40px;
    transform: rotate(180deg);
  }

  .down {
    background-repeat: no-repeat;
    background-image: url("@/assets/images/icon/jiantou-b.png");
    background-size: cover;
    background-position: center;
    width: 10px;
    height: 40px;
  }

  .qiu {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #2291ff;
  }

  .qiu_active {
    background-color: #fb2050;
  }
}
</style>
