export default {
  switch_lang: "切换语言",
  header: {
    home: "首页",
    matrix: "生态矩阵",
    platform: "平台优势",
    settled: "达人入驻",
  },
  home: {
    tilte: "全球化内容营销服务商",
    small_tilte_1: "更专业的服务，更好的服务效率",
    small_tilte_2: "助力中国企业赢在海外",
    botton: "了解更多",

    tilte_2: "聚焦为中国出海企业创造海外社媒内容矩阵",
    small_tilte_3: "海量KOL覆盖精准目标受众",
    small_tilte_4: "为国内跨境电商、游戏、APP等行业提供一站式内容营销",
  },
  matrix: {
    title: "生态矩阵",
    small_title: "ECOLOGICAL MATRIX",
    listData: [
      { title: "覆盖地区", total: "（如图所示）" },
      { title: "国家地区", total: "100+" },
      { title: "合作网红", total: "100,000+" },
      { title: "合作品牌", total: "100+" },
    ],
  },
  platform: {
    title: "平台优势",
    small_title: "PLATFORM ADVANTAGE",
    listData: [
      {
        title: "超强内容引流能力",
        content: "垂类流量精准覆盖，粉丝圈层营销直达，用户主动选择价值更高",
      },
      {
        title: "海量KOL达人资源",
        content: "海量国民内容消费意见领袖，流量真实度高，具有极强投放性价比",
        src: require("@/assets/images/icon/2.png"),
      },
      {
        title: "多维场景效果营销",
        content:
          "支持用户拉新、老客唤醒、线索收集、订单售卖、品牌活动等多种推广场景，满足客户的个性化营销诉求;并接受效果导向，为客户创造真实价值",
        src: require("@/assets/images/icon/3.png"),
      },
      {
        title: "优质内容全链触达",
        content:
          "全网优质写手入库，自有内容工厂提供内容创意、原创素材全程服务，可根据需求定制跨平台KOL达人矩阵，整合内容投放策略",
        src: require("@/assets/images/icon/4.png"),
      },
    ],
  },
  settled: {
    title: "达人入驻",
    small_title: "DAREN SETTLED IN",
    describe: "稳定的生意供给",
    small_describe: "kollz传媒与众多广告主达成深度合作，保障生意供给",
    listData: [
      {
        title: "即刻联系，快速接入",
        info: [
          "Email：support@kollz.cn",
          "Instagram:@kollz_official",
          "Tiktok:@kollz_official",
        ],
      },
    ],
    button: "立即了解",
    placeholder: "请输入您想咨询的问题，我们即将为您解答",
  },
};
