<template>
  <section class="section" id="settled">
    <div class="container">
      <div style="height: 70px" class="row" ref="title">
        <transition
          name="fade"
          enter-active-class="animate__animated animate__fadeInUp"
          leave-active-class="animate__animated animate__fadeOutDown"
        >
          <div v-if="title_show" style="height: 68px">
            <h1 class="section-title text-center">
              {{ title }}
              <img
                :src="require('@/assets/images/icon/matrix.png')"
                width="16px"
              />
            </h1>
            <p
              class="section-subtitle text-muted font-secondary text-uppercase"
            >
              {{ small_title }}
            </p>
          </div>
        </transition>
      </div>
    </div>
    <div class="container">
      <div
        style="
          display: flex;
          margin-right: -15px;
          margin-left: -15px;
          flex-wrap: wrap;
          flex-direction: column;
          min-height: 580px;
        "
        ref="content"
      >
        <transition-group
          name="fade"
          enter-active-class="animate__animated animate__fadeInUp"
          leave-active-class="animate__animated animate__fadeOutDown"
        >
          <div class="row margin-t-80" key="1" v-if="content_show">
            <div class="col-lg-8 offset-lg-2">
              <h1 class="section-title text-center">{{ describe }}</h1>
              <p
                class="section-subtitle font-secondary text-muted text-center padding-t-15"
              >
                {{ small_describe }}
              </p>

              <div class="d-flex justify-content-center" style="padding: 10px">
                <img
                  :src="require('@/assets/images/icon/jiantou-c.png')"
                  alt="KOL"
                  width="10"
                />
              </div>
            </div>
          </div>

          <div class="row img margin-t-20" key="2" v-if="content_show">
            <div
              class="col-lg-8 offset-md-2 u-flex justify-content-center align-items-center align-content-center"
              style="display: flex; flex-direction: column"
            >
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  :placeholder="placeholder"
                  aria-label="Recipient's username"
                  aria-describedby="button-addon2"
                />
              </div>
              <div>
                <a href="#" class="stretched-link" style="color: #ffffff">
                  {{ button }}
                </a>
              </div>
            </div>
          </div>

          <div
            class="row margin-t-80 u-flex justify-content-center"
            key="3"
            v-if="content_show"
          >
            <div>
              <div
                v-for="(item, index) in listData"
                class="text-left"
                :key="index"
                style="width: 100%"
              >
                <h4>{{ item.title }}</h4>
                <p
                  class="text-muted"
                  v-for="(el, num) in item.info"
                  :key="num"
                  style="margin: 0"
                >
                  {{ el }}
                </p>
              </div>
            </div>
          </div>
        </transition-group>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Settled",
  data() {
    return {
      title: "达人入驻",
      small_title: "DAREN SETTLED IN",
      describe: "稳定的生意供给",
      small_describe: "kollz传媒与众多广告主达成深度合作，保障生意供给",
      listData: [
        {
          title: "即刻联系，快速接入",
          info: ["Email：support@kollz.cn", "Instagram:@kollz_official"],
        },
      ],
      button: "立即了解",
      placeholder: "请输入您想咨询的问题，我们即将为您解答",
      listData: [],

      content_show: false,
      title_show: false,
    };
  },
  created() {
    this.setData();
  },

  mounted() {
    window.addEventListener("scroll", this.scrollHandle, true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollHandle, true);
  },
  methods: {
    scrollHandle() {
      const title = this.$refs.title.getBoundingClientRect();
      const titleTop = title.top;
      const titleBottom = title.bottom;
      // console.log(titleTop, titleBottom, title, window.innerHeight);
      if (titleTop < (window.innerHeight / 3) * 2) {
        this.title_show = true;
      } else {
        this.title_show = false;
      }

      const content = this.$refs.content.getBoundingClientRect();
      const contentTop = content.top;
      const contentBottom = content.bottom;

      if (contentTop < (window.innerHeight / 3) * 2) {
        // console.log(contentTop, contentBottom, content, window.innerHeight);
        this.content_show = true;
      } else {
        this.content_show = false;
      }
      // const offset = this.$el.getBoundingClientRect();
      // const offsetTop = offset.top;
      // const offsetBottom = offset.bottom;
      // // console.log(offsetTop, offsetBottom, window.innerHeight);
      // if (offsetTop < window.innerHeight && offsetBottom >= 0) {
      //   if (offsetTop < window.innerHeight / 3) {
      //     this.show = true;
      //     // window.removeEventListener("scroll", this.scrollHandle, true);
      //   }
      //   if (offsetTop > window.innerHeight) {
      //     this.show = false;
      //     // window.removeEventListener("scroll", this.scrollHandle, true);
      //   }
      //   // console.log("进入可视区域");
      //   // window.removeEventListener("scroll", this.scrollHandle, true);
      // } else {
      //   this.show = false;
      // }
    },
    setData() {
      this.listData = this.$t("settled.listData");
      this.title = this.$t("settled.title");
      this.small_title = this.$t("settled.small_title");
      this.describe = this.$t("settled.describe");
      this.small_describe = this.$t("settled.small_describe");
      this.button = this.$t("settled.button");
      this.placeholder = this.$t("settled.placeholder");
    },
  },
  watch: {
    "$store.state.app.lang"(val) {
      this.setData();
    },
  },
};
</script>

<style scoped lang="scss">
.section {
  height: calc(100vh);
  max-height: 990px;
  background-repeat: no-repeat;
  background-image: url("@/assets/images/bg.jpg");
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.img {
  height: 200px;
  background-image: url("@/assets/images/bg-4.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
