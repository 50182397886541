<template>
  <nav
    class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark"
    id="navbar"
  >
    <div class="container">
      <a class="navbar-brand logo text-uppercase" href="javascript: void(0);">
        <img :src="require('@/assets/logo.png')" alt="KOL" width="60" />
      </a>

      <div
        class="collapse navbar-collapse"
        id="navbarCollapse"
        style="justify-content: end"
      >
        <scrollactive active-class="active" :offset="60">
          <a
            :data-name="item.el"
            :href="`#${item.el}`"
            :key="item.el"
            class="nav-link scrollactive-item"
            v-for="item in listData"
          >
            {{ $t(`header.${item.title}`) }}
          </a>
        </scrollactive>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      listData: [
        { title: "home", el: "home", offset: 0 },
        { title: "matrix", el: "matrix", offset: -140 },
        { title: "platform", el: "platform", offset: -140 },
        { title: "settled", el: "settled", offset: 400 },
      ],
      activeName: "",
    };
  },
  mounted() {
    window.onscroll = function () {
      onwindowScroll();
      onCallback();
    };
    var navbar = document.getElementById("navbar");
    function onwindowScroll() {
      if (
        document.body.scrollTop > 40 ||
        document.documentElement.scrollTop > 40
      ) {
        navbar.style.backgroundColor = "#000625";
        navbar.style.padding = "10px";
      } else {
        navbar.style.backgroundColor = "";
        navbar.style.padding = "20px";
      }
    }

    var li = document.getElementsByClassName("active");

    const onCallback = () => {
      if (li.length == 0) return;
      let name = li[0].getAttribute("data-name") || "";
      if (name != this.activeName) {
        this.$emit("callback", name);
      }
      this.activeName = name;
    };
  },
  methods: {
    toggleMenu() {
      document.getElementById("navbarCollapse").classList.toggle("show");
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar-custom {
  padding: 20px 0px;
  width: 100%;
  border-radius: 0px;
  z-index: 999;
  margin-bottom: 0px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.sticky-wrapper {
  position: absolute;
  width: 100%;
}

.navbar-nav {
  margin-left: 50px;
  border-bottom: none;
}

.navbar-custom .navbar-brand {
  text-transform: uppercase;
  color: rgb(216, 216, 216) !important;
  font-weight: 700;
  letter-spacing: 2px;
  height: auto;
  font-family: "Sarabun", sans-serif;
}

.navbar-custom .navbar-nav li a {
  color: rgba(255, 255, 255, 0.6);
  font-size: 15px;
  background-color: transparent !important;
  padding: 15px 0;
  margin: 0 10px;
  transition: all 0.4s;
}

.navbar-custom .navbar-nav li a:hover,
.navbar-custom .navbar-nav li a:focus,
.navbar-custom .navbar-nav li a:active {
  color: #fff;
}

.navbar-custom .navbar-nav li.active a {
  color: #fff;
}

.is-sticky .navbar-custom {
  margin-top: 0px;
  padding: 10px 0px;
  background-color: #272a33;
  -moz-box-shadow: 0 10px 33px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 10px 33px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 33px rgba(0, 0, 0, 0.1);
}

.navbar-toggle {
  font-size: 24px;
  margin-top: 0px;
  margin-bottom: 0px;
  color: #ffffff;
}

.navbar-custom .btn-custom {
  margin-top: 4px;
  margin-bottom: 4px;
}

.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
  background-color: transparent;
  border-color: #337ab7;
}

.nav-link {
  color: #818181;
  transition: 0.5s;
  border-bottom: 2px solid rgba(255, 255, 255, 0);
  display: inline-block;
  position: relative;
}
.active {
  color: #ffffff;

  &::after {
    content: " ";
    width: 60%;
    height: 3px;
    border-radius: 1px;
    position: absolute;
    background-color: #fff;
    bottom: 0;
    left: 20%;
    transition: 0.3s;
  }
}
</style>
