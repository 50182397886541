import { getStorage, setStorage, getBrowserLang } from "@/locales/setupI18n";

const app = {
  state: {
    lang: getStorage("lang") || getBrowserLang(),
  },
  getters: {
    getLang: (state) => state.lang,
  },
  mutations: {
    setLang(state, lang) {
      state.lang = lang;
      setStorage("lang", lang);
    },
  },
  actions: {
    setLang: ({ commit }, data) => commit("setLang", data),
  },
};

export default app;
