<template>
  <section class="section bg-home home-half" id="home" :style="{}">
    <div class="container con">
      <transition
        enter-active-class="animate__animated animate__fadeInUp"
        leave-active-class="animate__animated animate__fadeOutDown"
      >
        <div class="main" v-if="show">
          <div class="decorate"></div>
          <div class="qiu"></div>
          <swiper class="swiper" ref="swiper" :options="swiperOption">
            <swiper-slide>
              <div class="one text-white">
                <img
                  :src="require('@/assets/logo-c.png')"
                  alt="KOL"
                  width="256"
                />
                <div class="row">
                  <div class="col-lg-12 text-white margin-t-20 text-left">
                    <h1 class="home-title">{{ ObjData.title || "2312321" }}</h1>
                    <h4 class="home-small-title">
                      {{ ObjData.small_tilte_1 }}
                    </h4>
                    <h4 class="home-small-title">
                      {{ ObjData.small_tilte_2 }}
                    </h4>

                    <button
                      type="button"
                      class="btn btn-custom navbar-btn btn-rounded margin-t-50"
                    >
                      {{ ObjData.botton }}
                    </button>
                  </div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div
                class="two text-white row u-flex justify-content-center align-items-center flex-row align-content-center"
              >
                <img
                  :src="require('@/assets/logo-c.png')"
                  alt="KOL"
                  width="256"
                />
                <div class="col-lg-12 text-white text-center margin-t-20">
                  <h1 class="home-title">{{ ObjData.tilte_2 }}</h1>
                  <h4 class="home-small-title margin-t-20">
                    {{ ObjData.small_tilte_3 }}
                  </h4>
                  <h4 class="home-small-title">{{ ObjData.small_tilte_4 }}</h4>

                  <button
                    type="button"
                    class="btn btn-custom navbar-btn btn-rounded margin-t-50"
                  >
                    {{ ObjData.botton }}
                  </button>
                </div>
              </div>
            </swiper-slide>

            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
      </transition>
    </div>
  </section>
</template>

<script>
export default {
  name: "test",
  data() {
    return {
      showIndex: 1,
      swiperOption: {
        autoplay: true,
        delay: 5000,
        speed: 1500,
        // effect: "fade",
        // disableOnInteraction: false,
        // fadeEffect: true,
        touchRatio: 2,
        pagination: {
          el: ".swiper-pagination",
          bulletClass: "set-swiper-pagination-bullet",
          bulletActiveClass: "set-swiper-pagination-bullet-active",
          clickable: true,
        },
        loop: true,
      },
      ObjData: {
        tilte: "",
        small_tilte_1: "",
        small_tilte_2: "",
        botton: "",

        tilte_2: "",
        small_tilte_3: "",
        small_tilte_4: "",
      },
      show: false,
    };
  },
  created() {
    this.setData();
    setTimeout(() => {
      this.show = true;
    }, 300);
  },
  computed: {
    swiper() {
      return this.$refs.swiper.$swiper;
    },
  },
  methods: {
    setData() {
      this.$set(this.ObjData, "title", this.$t("home.tilte"));
      this.$set(this.ObjData, "small_tilte_1", this.$t("home.small_tilte_1"));
      this.$set(this.ObjData, "small_tilte_2", this.$t("home.small_tilte_2"));
      this.$set(this.ObjData, "botton", this.$t("home.botton"));
      this.$set(this.ObjData, "tilte_2", this.$t("home.tilte_2"));
      this.$set(this.ObjData, "small_tilte_3", this.$t("home.small_tilte_3"));
      this.$set(this.ObjData, "small_tilte_4", this.$t("home.small_tilte_4"));
      // this.$nextTick(() => {
      //   this.$forceUpdate();
      // });
    },
  },
  watch: {
    "$store.state.app.lang"(val) {
      this.setData();
    },
  },
};
</script>

<style lang="less" scoped>
.section {
  height: calc(100vh);
  max-height: 990px;
  min-height: 990px;
}
.con {
  height: 600px;
}
.main {
  height: 600px;
  position: relative;
  .decorate {
    width: 100px;
    height: 100px;
    position: absolute;
    top: -50px;
    left: -50px;
    background-repeat: no-repeat;
    background-image: url("@/assets/images/home/decorate.png");
    background-size: cover;
    background-position: center;
  }
  .qiu {
    width: 60px;
    height: 60px;
    position: absolute;
    bottom: -30px;
    border-radius: 30px;
    left: -30px;
    background-color: #919fa5;
  }
}
.swiper {
  height: 600px;
  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    font-size: 12px;
    background-color: white;
  }
}
.one {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px 40px;
  justify-content: center;
  background-repeat: no-repeat;
  background-image: url("@/assets/images/home/one.jpg");
  background-size: cover;
  background-position: center;
}
.two {
  width: 101%;
  height: 100%;
  background-repeat: no-repeat;
  background-image: url("@/assets/images/home/two.jpg");
  background-size: cover;
  background-position: center;
}

.home-title {
  font-size: 46px;
  line-height: 64px;
}

.home-small-title {
  font-size: 16px;
  font-weight: normal;
  // text-transform: uppercase;
  // letter-spacing: 7px;
}
.home-desc {
  color: rgba(255, 255, 255, 0.7);
  font-size: 15px;
  max-width: 600px;
}
</style>

<style>
.set-swiper-pagination-bullet {
  display: inline-block;
  width: 18px;
  height: 4px;
  border-radius: 2px;
  background-color: #e3e3e35e;
  transition: width 0.3s;
  margin: 4px 4px;
}
.set-swiper-pagination-bullet-active {
  background-color: #ffffff;
  width: 32px;
  transition: width 0.3s;
}
.swiper-pagination-bullet-active {
  background-color: seagreen;
}
</style>
