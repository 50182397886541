<template>
  <section class="section" id="platform">
    <div class="container">
      <div style="height: 70px" ref="title">
        <transition
          name="fade"
          enter-active-class="animate__animated animate__fadeInUp"
          leave-active-class="animate__animated animate__fadeOutDown"
        >
          <div v-if="title_show" style="height: 68px">
            <h1 class="section-title">
              {{ title }}
              <img
                :src="require('@/assets/images/icon/matrix.png')"
                width="16px"
              />
            </h1>
            <p class="section-subtitle font-secondary">
              {{ small_title }}
            </p>
          </div>
        </transition>
      </div>

      <div class="main" ref="content">
        <transition-group
          enter-active-class="animate__animated animate__fadeInLeft"
          leave-active-class="animate__animated animate__fadeOutLeft"
        >
          <template v-if="content_show">
            <div class="image" key="111111">
              <div class="img"></div>
            </div>

            <div class="left" key="222222">
              <div class="yuan"></div>
            </div>
          </template>
        </transition-group>
        <transition
          name="fade"
          enter-active-class="animate__animated animate__fadeInRight"
          leave-active-class="animate__animated animate__fadeOutRight"
        >
          <template v-if="content_show">
            <div class="right d-flex flex-column justify-content-center">
              <div
                class="d-flex flex-row sss"
                v-for="(item, index) in list"
                :key="index"
              >
                <div>
                  <img :src="item.src" :alt="item.title" width="40" />
                </div>
                <div style="margin-left: 20px">
                  <h4>{{ item.title }}</h4>
                  <p class="text-muted">
                    {{ item.content }}
                  </p>
                </div>
              </div>
            </div>
          </template>
        </transition>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Platform",
  data() {
    return {
      title: "",
      small_title: "",
      list: [
        {
          title: "超强内容引流能力",
          content: "垂类流量精准覆盖，粉丝圈层营销直达，用户主动选择价值更高",
          src: require("@/assets/images/icon/1.png"),
        },
        {
          title: "海量KOL达人资源",
          content: "海量国民内容消费意见领袖，流量真实度高，具有极强投放性价比",
          src: require("@/assets/images/icon/2.png"),
        },
        {
          title: "多维场景效果营销",
          content:
            "支持用户拉新、老客唤醒、线索收集、订单售卖、品牌活动等多种推广场景，满足客户的个性化营销诉求;并接受效果导向，为客户创造真实价值",
          src: require("@/assets/images/icon/3.png"),
        },
        {
          title: "优质内容全链触达",
          content:
            "全网优质写手入库，自有内容工厂提供内容创意、原创素材全程服务，可根据需求定制跨平台KOL达人矩阵，整合内容投放策略",
          src: require("@/assets/images/icon/4.png"),
        },
      ],

      title_show: false,
      content_show: false,
    };
  },
  created() {
    this.setData();
  },

  mounted() {
    window.addEventListener("scroll", this.scrollHandle, true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollHandle, true);
  },
  methods: {
    scrollHandle() {
      const title = this.$refs.title.getBoundingClientRect();
      const titleTop = title.top;
      const titleBottom = title.bottom;
      if (titleTop < (window.innerHeight / 3) * 2) {
        this.title_show = true;
      } else {
        this.title_show = false;
      }

      const content = this.$refs.content.getBoundingClientRect();
      const contentTop = content.top;
      const contentBottom = content.bottom;

      if (contentTop < (window.innerHeight / 3) * 2) {
        // console.log(contentTop, contentBottom, content, window.innerHeight);
        this.content_show = true;
      } else {
        this.content_show = false;
      }
    },
    setData() {
      this.$t("platform.listData").forEach((item, index) => {
        this.$set(this.list, index, {
          ...this.list[index],
          ...this.$t("platform.listData")[index],
        });
      });
      this.title = this.$t("platform.title");
      this.small_title = this.$t("platform.small_title");
    },
  },
  watch: {
    "$store.state.app.lang"(val) {
      this.setData();
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.section {
  background-repeat: no-repeat;
  background-image: url("@/assets/images/bg-home.jpg");
  background-size: cover;
  background-position: center;
}
.border {
  border: 3px solid #ffffff;
  width: 100%;
  height: 80%;
}
.main {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // flex-direction: row;
  position: relative;
  height: 600px;
  .left {
    position: absolute;
    z-index: 0;
    top: 110px;
    width: 600px;
    height: 400px;
    border: 3px solid #ffffff;

    .yuan {
      width: 20px;
      height: 20px;
      border-radius: 10px;
      background-color: #ffffff;
      position: absolute;
      bottom: -10px;
      left: -10px;
    }
  }

  .image {
    position: absolute;
    z-index: 2;
    top: 110px;
    width: 600px;
    height: 400px;
    .img {
      height: 100%;
      width: 100%;
      background-image: url("@/assets/3peo.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  .right {
    z-index: 1;
    right: 0;
    position: absolute;
    top: 0;
    padding: 0 80px 0 150px;
    width: 600px;
    height: 600px;
    background-color: #ffffff;
  }
}
.sss {
  & + & {
    padding-top: 10px;
  }
  &:hover {
    transform: scale(1.05);
    transition: 0.3s;
  }
}

.section-title {
  color: #ffffff;
}

.section-subtitle {
  color: #d3d3d3;
}
p {
  line-height: normal;
}
</style>
