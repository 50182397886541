export default {
  switch_lang: "change language",
  header: {
    home: "Home",
    matrix: "Matrix",
    platform: "Platform",
    settled: "Settled",
  },
  home: {
    tilte: "As a global content marketing agency",
    small_tilte_1:
      "Kollz provides more professional service and higher efficiency",
    small_tilte_2: "To help Chinese companies strategize overseas",
    botton: "Learn more",

    tilte_2: "Focus on overseas social media content matrix",
    small_tilte_3: "Kollz reaches its target audience with massive KOL",
    small_tilte_4:
      "Kollz provides one-stop content marketing for Chinese cross-border companies, such as games, apps, and other industries.",
  },
  matrix: {
    title: "Ecological Matrix",
    small_title: "",
    listData: [
      { title: "Covered area", total: "（As shown）" },
      { title: "Covered Countries", total: "100+" },
      { title: "Cooperative influencers", total: "100,000+" },
      { title: "Cooperative brands", total: "100+" },
    ],
  },
  platform: {
    title: "Platform Advantages",
    small_title: "",
    listData: [
      {
        title: "Superb content capabilities",
        content:
          "Accurate coverage of vertical flow and direct marketing to the target audience make users choose more actively",
        src: require("@/assets/images/icon/1.png"),
      },
      {
        title: "Massive Cooperative Influencers",
        content:
          "Massive KOL of content type and great performance in brand promotion",
        src: require("@/assets/images/icon/2.png"),
      },
      {
        title:
          "Massive KOL of content type and great performance in brand promotion",
        content:
          "Massive KOL of content type and great performance in brand promotion;",
        src: require("@/assets/images/icon/3.png"),
      },
      {
        title: "High-quality content",
        content:
          "Professional creative team, guidance for viral videos, more support of flow",
        src: require("@/assets/images/icon/4.png"),
      },
    ],
  },
  settled: {
    title: "For Influencers",
    small_title: " ",
    describe: "Supply stable business order volume",
    small_describe:
      "Kollz maintains in-depth cooperation with many companies to ensure business supply",
    listData: [
      {
        title: "Contact us",
        info: [
          "Email：support@kollz.cn",
          "Instagram:@kollz_official",
          "Tiktok:@kollz_official",
        ],
      },
    ],
    button: "Consult now",
    placeholder:
      "Please enter the question you want to ask, we will answer it for you soon",
  },
};
