<template>
  <section class="section" id="matrix">
    <div class="container">
      <div style="height: 70px" ref="title">
        <transition
          name="fade"
          enter-active-class="animate__animated animate__fadeInUp"
          leave-active-class="animate__animated animate__fadeOutDown"
        >
          <div v-if="title_show" style="height: 68px">
            <h1 class="section-title">
              {{ title }}
              <img
                :src="require('@/assets/images/icon/matrix.png')"
                width="16px"
              />
            </h1>
            <p class="section-subtitle text-muted font-secondary">
              {{ small_title }}
            </p>
          </div>
        </transition>
      </div>

      <div style="min-height: 600px" ref="content">
        <transition
          name="fade"
          enter-active-class="animate__animated animate__fadeInUp"
          leave-active-class="animate__animated animate__fadeOutDown"
        >
          <div class="row align-items-center" v-if="content_show">
            <div class="col-lg-5">
              <div v-for="(item, index) in listData" :key="index">
                <h4 class="mt-3">
                  <div class="number">
                    0{{ index + 1 }}
                    <div class="red"></div>
                    <div class="blue"></div>
                  </div>
                  <a href class="blog-title">
                    {{ item.title }}：<span :class="index != 0 ? 'cb' : ''">{{
                      item.total
                    }}</span>
                  </a>
                </h4>
              </div>
            </div>
            <div
              class="col-lg-7 map"
              :class="lang == 'zh-CN' ? 'map-zh' : 'map-en'"
            ></div>
          </div>
        </transition>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Matrix",
  data() {
    return {
      lang: "zh-CN",
      title: "",
      small_title: "",
      listData: [
        { title: "覆盖地区", total: "（如图所示）" },
        { title: "国家地区", total: "100+" },
        { title: "合作网红", total: "10000+" },
        { title: "合作品牌", total: "100+" },
      ],

      content_show: false,
      title_show: false,
    };
  },
  created() {
    this.lang = this.$store.getters.getLang;
    this.setData();

    // setTimeout(() => {
    //   this.title_show = true;
    //   this.content_show = true;
    // }, 1000);
  },
  mounted() {
    window.addEventListener("scroll", this.scrollHandle, true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollHandle, true);
  },
  methods: {
    scrollHandle() {
      const title = this.$refs.title.getBoundingClientRect();
      const titleTop = title.top;
      const titleBottom = title.bottom;
      // console.log(titleTop, titleBottom, title, window.innerHeight);
      if (titleTop < (window.innerHeight / 3) * 2) {
        this.title_show = true;
      } else {
        this.title_show = false;
      }

      const content = this.$refs.content.getBoundingClientRect();
      const contentTop = content.top;
      const contentBottom = content.bottom;

      if (contentTop < (window.innerHeight / 3) * 2) {
        // console.log(contentTop, contentBottom, content, window.innerHeight);
        this.content_show = true;
      } else {
        this.content_show = false;
      }
      // if (offsetTop < window.innerHeight && offsetBottom >= 0) {
      //   if (offsetTop < window.innerHeight / 3) {
      //     this.show = true;
      //     // window.removeEventListener("scroll", this.scrollHandle, true);
      //   }
      //   if (offsetBottom < (window.innerHeight / 3) * 2) {
      //     this.show = false;
      //     // window.removeEventListener("scroll", this.scrollHandle, true);
      //   }
      //   // console.log("进入可视区域");
      //   // window.removeEventListener("scroll", this.scrollHandle, true);
      // } else {
      //   this.show = false;
      // }
    },
    setData() {
      this.$set(this, "listData", this.$t("matrix.listData"));
      this.title = this.$t("matrix.title");
      this.small_title = this.$t("matrix.small_title");
    },
  },
  watch: {
    "$store.state.app.lang"(val) {
      this.lang = val;
      this.setData();
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  max-width: 1140px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.section {
  background-repeat: no-repeat;
  background-image: url("@/assets/images/bg.jpg");
  background-size: cover;
  background-position: center;
}
.map {
  height: 600px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.map-zh {
  background-image: url("@/assets/images/map.png");
}
.map-en {
  background-image: url("@/assets/images/map-en.png");
}
.col-md-2 {
  display: flex;
  align-items: center;
}
.number {
  display: inline-block;
  position: relative;
  font-family: fangsong;
  font-style: italic;
  .red {
    width: 10px;
    height: 2px;
    background-color: blue;
    position: absolute;
    left: -3px;
    bottom: 1px;
  }
  .blue {
    width: 2px;
    height: 10px;
    background-color: red;
    position: absolute;
    bottom: 3px;
    left: -3px;
  }
}

.blog-title {
  color: #212529;
  transition: all 0.5s;
  margin-left: 16px;
}
.cb {
  color: #0052c9;
}
</style>
